import { post } from "@src/appV2/api";
import { environmentConfig } from "@src/appV2/environment";
import { useMutation, type UseMutationOptions } from "@tanstack/react-query";
import { type AxiosError, isAxiosError } from "axios";
import { z } from "zod";

export enum ShiftMarkedNonIpReasons {
  NO_LOCATION = "HCP failed to confirm location",
  NFC_FAILURE = "HCP failed to scan NFC",
  MOBILE_FORGOT_TO_CLOCK_IN = "Mobile: HCP failed to clock in",
  MOBILE_HCP_IS_OUTSIDE_GEOFENCE = "Mobile: HCP is outside geofence and failed to clock in",
  API_CALL = "/markNonInstant API call",
  CAUGHT_ON_AUTO_CLOCK_OUT = "HCP forgot to clock in, caught on auto clock out",
  REACHED_VERIFICATION_WITHOUT_PAYMENT = "SUSPICIOUS, Reached the verification step without initial payment",
}

const markNonInstantRequestSchema = z.object({
  shiftId: z.string(),
  reason: z.nativeEnum(ShiftMarkedNonIpReasons),
});
const markNonInstantResponseSchema = z.object({
  response: z.object({
    isInstantPay: z.boolean(),
    isChangedToNonInstantPay: z.boolean(),
    _id: z.string(),
  }),
});

export type MarkNonInstantRequest = z.infer<typeof markNonInstantRequestSchema>;
export type MarkNonInstantResponse = z.infer<typeof markNonInstantResponseSchema>;

export function useMarkShiftNonInstant(
  options: UseMutationOptions<
    MarkNonInstantResponse["response"],
    AxiosError,
    MarkNonInstantRequest
  > = {}
) {
  return useMutation({
    mutationFn: async (data: MarkNonInstantRequest) => {
      try {
        const response = await post({
          url: `${environmentConfig.REACT_APP_BASE_API_URL}/v1/shifts/markNonInstant`,
          data,
          requestSchema: markNonInstantRequestSchema,
          responseSchema: markNonInstantResponseSchema,
        });

        return response.data.response;
      } catch (error) {
        if (isAxiosError(error)) {
          const errorResponse = error.response?.data as { message: string };

          if (errorResponse?.message) {
            throw new Error(errorResponse.message);
          }
        }

        throw error;
      }
    },
    ...options,
  });
}
