import { type ApiResponse, post } from "@src/appV2/api";
import { environmentConfig } from "@src/appV2/environment";
import { shiftSchema } from "@src/appV2/Shifts/Shift/types";
import {
  useMutation,
  type UseMutationOptions,
  type UseMutationResult,
} from "@tanstack/react-query";
import { type AxiosError, isAxiosError } from "axios";
import { z } from "zod";

export enum ShiftStage {
  CLOCK_IN = "CLOCK_IN",
  /**
   * SKIP_LUNCH, LUNCH_OUT and LUNCH_IN are used by the HCP mobile app.
   * We've updated enum keys to be more precise and to avoid confusion.
   */
  SKIP_BREAK = "SKIP_LUNCH",
  BREAK_START = "LUNCH_OUT",
  BREAK_END = "LUNCH_IN",
  CLOCK_OUT = "CLOCK_OUT",
  SHIFT_TIME_DONE = "SHIFT_TIME_DONE",
}

export enum ActionCheckType {
  LOCATION = "LOCATION",
  NFC = "NFC",
  MANUAL = "MANUAL",
  INTEGRATION = "INTEGRATION",
}

const recordTimekeepingActionRequestSchema = z.object({
  shiftId: z.string(),
  stage: z.nativeEnum(ShiftStage),
  shiftActionCheck: z.nativeEnum(ActionCheckType),

  complianceProof: z
    .object({
      bucketName: z.string(),
      fileKey: z.string(),
    })
    .optional(),

  appType: z.string().optional(),
  connectivityMode: z.string().optional(),
  location: z.number().array().optional(),
  locationType: z.string().optional(),
});

/**
 * Record Timekeeping Action returns the entire shift object,
 * but we only need the clockInOut and lunchInOut fields.
 *
 * We should only add the fields we actually use to the response schema,
 * in order to avoid unnecessary coupling to the API return.
 */
const recordTimekeepingActionResponseSchema = shiftSchema.pick({
  clockInOut: true,
  lunchInOut: true,
});

export type RecordTimekeepingActionRequest = z.infer<typeof recordTimekeepingActionRequestSchema>;
export type RecordTimekeepingActionResponse = z.infer<typeof recordTimekeepingActionResponseSchema>;

function getRecordTimekeepingActionPath(shiftId: string) {
  return `${environmentConfig.REACT_APP_BASE_API_URL}/shifts/record_timekeeping_action/${shiftId}`;
}

export function useRecordShiftTimekeepingAction<T extends RecordTimekeepingActionRequest>(
  options: UseMutationOptions<ApiResponse<RecordTimekeepingActionResponse>, AxiosError, T> = {}
): UseMutationResult<ApiResponse<RecordTimekeepingActionResponse>, AxiosError, T> {
  return useMutation({
    mutationFn: async (data: T) => {
      const { shiftId, ...requestData } = data;

      try {
        return await post({
          url: getRecordTimekeepingActionPath(shiftId),
          data: requestData,
          requestSchema: recordTimekeepingActionRequestSchema.omit({ shiftId: true }),
          responseSchema: recordTimekeepingActionResponseSchema,
        });
      } catch (error) {
        if (isAxiosError(error)) {
          const errorResponse = error.response?.data as { message: string };

          if (errorResponse?.message) {
            throw new Error(errorResponse.message);
          }
        }

        throw error;
      }
    },
    ...options,
  });
}
