import { Callout } from "@clipboard-health/ui-components";
import { useHistory } from "react-router-dom";

import { BOOKING_REQUESTS_FULL_PATH } from "../path";

export function BookingRequestsCallout() {
  const history = useHistory();

  return (
    <Callout
      title="Pending Requests"
      iconType="date-clock"
      sx={(theme) => ({
        color: theme.palette.shift?.bookingRequest.text,
        backgroundColor: theme.palette.shift?.bookingRequest.background,
      })}
      onClick={() => {
        history.push(BOOKING_REQUESTS_FULL_PATH);
      }}
    />
  );
}
