import { type UseGetQueryOptions } from "@src/appV2/api";
import { get } from "@src/appV2/api/api";
import { workerShiftSchema } from "@src/appV2/Shifts/Shift/types";
import { useQuery, type UseQueryResult } from "@tanstack/react-query";
import { z } from "zod";

export const getWorkerShiftResponseSchema = z.object({
  success: z.boolean(),
  response: workerShiftSchema,
  error: z.unknown().nullable(),
});

export type GetWorkerShiftResponse = z.infer<typeof getWorkerShiftResponseSchema>;

export function getWorkerShiftPath(shiftId: string) {
  return `/v1/shifts/myShifts/details/${shiftId}`;
}

export function useGetWorkerShift(
  shiftId: string,
  options: UseGetQueryOptions<GetWorkerShiftResponse> = {}
): UseQueryResult<GetWorkerShiftResponse> {
  return useQuery<GetWorkerShiftResponse, unknown, GetWorkerShiftResponse, any>({
    queryKey: [getWorkerShiftPath(shiftId)],
    queryFn: async () => {
      const response = await get({
        url: getWorkerShiftPath(shiftId),
        responseSchema: getWorkerShiftResponseSchema,
      });

      /**
       * We parse the response here to ensure that the response is valid.
       */
      workerShiftSchema.parse(response.data.response);

      return response.data;
    },

    ...options,
  });
}
